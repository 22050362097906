import React, {useState, useRef} from 'react'
import styled from 'styled-components'

import {BaseError, Button, FormControl, Inline, Input, Stack, Text} from '@pleo-io/telescope'
import {Attention} from '@pleo-io/telescope-icons'

import {DosAndDonts} from '../dos-and-donts'

const Form = styled.form`
    width: 100%;
`

const Fields = styled(Inline).attrs({space: 36})`
    width: 100%;
`

const Email = styled(Input)`
    flex: 1;
`

function isValidEmail(email: string) {
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
}

export const ShortFormValidation = () => {
    const [isFormInvalid, setIsFormInvalid] = useState(false)
    const [email, setEmail] = useState('fake@email.')
    const inputRef = useRef<HTMLInputElement>(null)

    const onSubmit = (e: any) => {
        e.preventDefault()
        if (!!email && isValidEmail(email)) {
            alert('All good!')
        } else {
            inputRef.current?.focus()
            setIsFormInvalid(true)
        }
    }

    const onChangeValue = (value: string) => {
        setIsFormInvalid(false)
        setEmail(value)
    }

    const errorMessage =
        email === '' ? 'Please enter your email address.' : 'Please enter a valid email address.'

    return (
        <Form onSubmit={onSubmit}>
            <Stack space={36}>
                <Text>
                    <Attention /> Try submitting this form as is.
                </Text>
                <Fields>
                    <Email
                        innerRef={inputRef}
                        name="email"
                        label="Email address"
                        value={email}
                        onChange={(e) => onChangeValue(e.target.value)}
                        required
                        isInvalid={isFormInvalid}
                        renderError={() => isFormInvalid && <BaseError>{errorMessage}</BaseError>}
                    />
                    <Input name="phone" label="Phone number (optional)" />
                </Fields>
                <Button type="submit" variant="primary">
                    Submit
                </Button>
            </Stack>
        </Form>
    )
}

export const Labels = () => (
    <DosAndDonts
        doText="Use clear and concise labels."
        dontText="Use vague or overly long labels."
        doExample={
            <FormControl>
                <FormControl.Label>Invoice number</FormControl.Label>
                <Input name="input" />
            </FormControl>
        }
        dontExample={
            <FormControl>
                <FormControl.Label>Add invoice number here</FormControl.Label>
                <Input name="input" />
            </FormControl>
        }
    />
)

export const Placeholders = () => (
    <DosAndDonts
        doText="Use hint text to help users fill out a field correctly."
        dontText="Use placeholder text for validation instructions."
        doExample={
            <FormControl>
                <FormControl.Label>Invoice number</FormControl.Label>
                <FormControl.HintText>Enter a 10 digit number</FormControl.HintText>
                <Input name="input" />
            </FormControl>
        }
        dontExample={
            <FormControl>
                <FormControl.Label>Name</FormControl.Label>
                <Input name="input" placeholder="Enter your full name" />
            </FormControl>
        }
    />
)

export const RequiredAndOptionalFields = () => (
    <DosAndDonts
        doText="Mark the minority as required or optional."
        dontText="Mark the majority and don't use asterisks."
        doExample={
            <Stack space={24}>
                <FormControl>
                    <FormControl.Label>First name</FormControl.Label>
                    <Input name="input" />
                </FormControl>
                <FormControl>
                    <FormControl.Label>Last name</FormControl.Label>
                    <Input name="input" />
                </FormControl>
                <FormControl>
                    <FormControl.Label>Email address (optional)</FormControl.Label>
                    <Input name="input" placeholder="DD/MM/YYYY" />
                </FormControl>
            </Stack>
        }
        dontExample={
            <Stack space={24}>
                <FormControl>
                    <FormControl.Label>First name*</FormControl.Label>
                    <Input name="input" />
                </FormControl>
                <FormControl>
                    <FormControl.Label>Last name*</FormControl.Label>
                    <Input name="input" />
                </FormControl>
                <FormControl>
                    <FormControl.Label>Email address</FormControl.Label>
                    <Input name="input" placeholder="DD/MM/YYYY" />
                </FormControl>
            </Stack>
        }
    />
)

export const HintText = () => (
    <DosAndDonts
        doText="Use hint text to give users clear guidance for data entry."
        dontText="Repeat the label or other form details in the hint text."
        doExample={
            <FormControl>
                <FormControl.Label>Invoice date</FormControl.Label>
                <FormControl.HintText>The date this invoice was issued to you</FormControl.HintText>
                <Input name="input" />
            </FormControl>
        }
        dontExample={
            <FormControl>
                <FormControl.Label>Invoice date</FormControl.Label>
                <FormControl.HintText>Enter the invoice date below</FormControl.HintText>
                <Input name="input" />
            </FormControl>
        }
    />
)

export const ErrorMessages = () => (
    <DosAndDonts
        doText="Communicate error messages in a way that guides the user towards a solution."
        dontText="Simply state what’s wrong and leave users guessing as to how to resolve it."
        doExample={
            <FormControl>
                <FormControl.Label>Credit card number (required)</FormControl.Label>
                <Input name="input" isInvalid />
                <FormControl.Error>Enter your credit card number.</FormControl.Error>
            </FormControl>
        }
        dontExample={
            <FormControl>
                <FormControl.Label>Credit card number (required)</FormControl.Label>
                <Input name="input" isInvalid />
                <FormControl.Error>Invalid field.</FormControl.Error>
            </FormControl>
        }
    />
)

export const SubmitButtons = () => (
    <DosAndDonts
        doText="Keep submit buttons enabled until the user has filled out the form correctly."
        dontText="Disable submit buttons until the user has filled out the form correctly."
        doExample={<Button variant="primary">Submit</Button>}
        dontExample={
            <Button variant="primary" disabled>
                Submit
            </Button>
        }
    />
)
