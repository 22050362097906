import { Props, FeatureDescription } from 'docs';
import { CurrencyInput, FormikCurrencyInput, FormControl, Stack } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  Props,
  FeatureDescription,
  CurrencyInput,
  FormikCurrencyInput,
  FormControl,
  Stack,
  React
};