import {styled} from 'styled-components'

import tokens from '@pleo-io/telescope-tokens'

export const Column = styled.div`
    flex: 1;

    img {
        border-radius: ${tokens.arc12};
    }
`

export const Columns = styled.div`
    display: flex;
    flex-direction: column-reverse;
    gap: ${tokens.spacing36};
    margin: ${tokens.spacing24} 0;

    @media (width > 500px) {
        flex-direction: row;
    }

    & + & {
        margin-top: ${tokens.spacing36};

        @media (width > 500px) {
            margin-top: ${tokens.spacing60};
        }
    }
`
