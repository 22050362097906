import React from 'react'

import * as pictograms from '@pleo-io/telescope-pictograms'
import tokens from '@pleo-io/telescope-tokens'

import {CardStatic, useCardStatic} from '../card-static'
import {OverviewPage} from '../overview-page'

const getCode = (name: string) => {
    return `
import {${name}} from '@pleo-io/telescope-pictograms'

const MyComponent = () => <${name} />
`
}

export const PictogramsList = () => {
    const {filterValue, handleFilterChange, filteredList, location} = useCardStatic({
        list: Object.values(pictograms)
    })

    return (
        <OverviewPage>
            <OverviewPage.Search
                name="pictograms-search"
                placeholder="Search pictograms..."
                value={filterValue}
                onChange={handleFilterChange}
            />
            <OverviewPage.Cards>
                {filteredList.map((Pictogram) => {
                    return (
                        <CardStatic key={Pictogram.displayName} paddingBlock={tokens.spacing36}>
                            <CardStatic.CopyButton text={getCode(Pictogram.displayName)} />
                            <CardStatic.Asset maxHeight={100}>
                                <Pictogram />
                            </CardStatic.Asset>
                            <CardStatic.Name location={location}>
                                {Pictogram.displayName}
                            </CardStatic.Name>
                        </CardStatic>
                    )
                })}
            </OverviewPage.Cards>
        </OverviewPage>
    )
}
