import type {MDXProviderComponents} from '@mdx-js/react'
import {preToCodeBlock} from 'mdx-utils'
import React from 'react'
import styled, {css} from 'styled-components'

import {Text, List, ListItem, tokens, Link, Callout} from '@pleo-io/telescope'

import {Code} from '@/components/code/code'
import {Key} from '@/components/keyboard/keyboard'
import {HeadingLink} from '@/components/layout/heading-link'

import {Table, TableBody, TableBodyData, TableBodyRow, TableHead, TableHeadData} from '../docs'

const noMarginTopHeading = css`
    &:first-child,
    /* We sometimes use div elements for heading ids */
    div:first-child:empty ~ & {
        margin-top: 0;
    }
`

const H2 = styled(HeadingLink).attrs({variant: 'xlarge-accent', element: 'h2', weight: 'semibold'})`
    margin-top: ${tokens.spacing48};
    margin-bottom: ${tokens.spacing10};
    ${noMarginTopHeading};
`

export const H3 = styled(HeadingLink).attrs({
    variant: 'large-accent',
    element: 'h3',
    weight: 'semibold'
})`
    margin-top: ${tokens.spacing36};
    margin-bottom: ${tokens.spacing10};
    ${noMarginTopHeading};
`

const H4 = styled(HeadingLink).attrs({
    variant: 'medium-default',
    element: 'h4',
    weight: 'semibold'
})`
    margin-top: ${tokens.spacing36};
    margin-bottom: ${tokens.spacing10};
    ${noMarginTopHeading};
`

export const P = styled(Text).attrs({variant: 'medium-default', as: 'p'})`
    + p {
        margin: ${tokens.spacing20} 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
`

// TODO: We should probably make this adjustments to the List component
// https://linear.app/pleo/issue/DO-3286/improve-styling-for-list-component
export const UL = styled(List).attrs({listStyle: 'bullet'})`
    list-style-position: outside;
    line-height: ${tokens.lineHeight3};
    padding-left: ${tokens.spacing14};
    margin: ${tokens.spacing20} 0;
`

// TODO: We should probably make this adjustments to the ListItem component
// https://linear.app/pleo/issue/DO-3286/improve-styling-for-list-component
export const LI = styled(ListItem)`
    line-height: ${tokens.lineHeight2};
`

const OL = styled(List).attrs({listStyle: 'number', as: 'ol'})`
    margin: ${tokens.spacing20} 0;
`

export const A = styled(Link)``

export const Blockquote = ({children}: {children: React.ReactNode}) => {
    return (
        <Callout css={{marginBlock: tokens.spacing20}}>
            <Callout.Text>{children}</Callout.Text>
        </Callout>
    )
}

// Map markdown syntax components to telescope components
// https://mdxjs.com/getting-started#mdxprovider
export const TelescopeMDXComponents: MDXProviderComponents = {
    h1: (props) => (
        <Text variant="4xlarge-accent" as="h1" weight="semibold" space={10} {...props} />
    ),
    h2: H2,
    h3: H3,
    h4: H4,
    p: P,
    a: A,
    pre: (preProps) => {
        const props = preToCodeBlock(preProps)
        // if there's a codeString and some props, we passed the test
        if (props) {
            return <Code {...props} />
        } else {
            // it's possible to have a pre without a code in it
            return <pre {...preProps} style={{margin: 40}} />
        }
    },
    code: Code,
    // Will be fixed after update to latest version of @mdx-js/react
    // @ts-expect-error Type is not assignable to type 'ReactNode'
    kbd: Key,
    blockquote: Blockquote,
    ul: UL,
    ol: OL,
    li: LI,
    table: Table,
    // Will be fixed after update to latest version of @mdx-js/react
    // @ts-expect-error Type is not assignable to type 'ReactNode'
    tbody: TableBody,
    tr: TableBodyRow,
    td: TableBodyData,
    // Will be fixed after update to latest version of @mdx-js/react
    // @ts-expect-error Type is not assignable to type 'ReactNode'
    thead: TableHead,
    th: TableHeadData
}
