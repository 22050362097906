import { Example, Labels, Placeholders, HintText, ErrorMessages, ShortFormValidation, SubmitButtons, RequiredAndOptionalFields, FeatureDescription } from 'docs';
import { FormControl, Input, Button, Stack, Link, Text } from '@pleo-io/telescope';
import { Attention } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  Example,
  Labels,
  Placeholders,
  HintText,
  ErrorMessages,
  ShortFormValidation,
  SubmitButtons,
  RequiredAndOptionalFields,
  FeatureDescription,
  FormControl,
  Input,
  Button,
  Stack,
  Link,
  Text,
  Attention,
  React
};