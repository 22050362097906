import { Features, Feature, ComponentExample, RelatedComponents, FeatureDescription } from 'docs';
import { Stack, Textarea, FormControl } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  Features,
  Feature,
  ComponentExample,
  RelatedComponents,
  FeatureDescription,
  Stack,
  Textarea,
  FormControl,
  React
};