import { Features, Feature, ComponentExample, RelatedComponents, FeatureDescription } from 'docs';
import { Inline, RadioGroup, RadioButton, Stack, Text, VisuallyHidden, Label, FormControl } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  Features,
  Feature,
  ComponentExample,
  RelatedComponents,
  FeatureDescription,
  Inline,
  RadioGroup,
  RadioButton,
  Stack,
  Text,
  VisuallyHidden,
  Label,
  FormControl,
  React
};