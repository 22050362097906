import { Props, FeatureDescription } from 'docs';
import { RadioGroup, RadioButton, Checkbox, Stack, Inline, FormControl } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  Props,
  FeatureDescription,
  RadioGroup,
  RadioButton,
  Checkbox,
  Stack,
  Inline,
  FormControl,
  React
};