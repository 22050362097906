import { ComponentExample, Features, Feature, RawPropsTable, RelatedComponents, FeatureDescription } from 'docs';
import { Select, BaseError, Link } from '@pleo-io/telescope';
import { CategoryNone, CategoryTravel, CategoryEquipment, CategoryEntertainment, User } from '@pleo-io/telescope-icons';
import jeppe from '@/assets/jeppe.png';
import niccolo from '@/assets/niccolo.png';
import * as React from 'react';
export default {
  ComponentExample,
  Features,
  Feature,
  RawPropsTable,
  RelatedComponents,
  FeatureDescription,
  Select,
  BaseError,
  Link,
  CategoryNone,
  CategoryTravel,
  CategoryEquipment,
  CategoryEntertainment,
  User,
  jeppe,
  niccolo,
  React
};