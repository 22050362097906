import {withPrefix} from 'gatsby'
import type {AnchorHTMLAttributes} from 'react'
import React from 'react'

import {A} from '../layout/mdx-components'

interface InternalLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    href: string
}
// We need to use the withPrefix function to correctly resolve the href
// for preview deploys.
export const InternalLink: React.FC<InternalLinkProps> = ({href, children, ...props}) => (
    <A href={withPrefix(href)} {...props}>
        {children}
    </A>
)
