import { Features, Feature, ComponentExample, RelatedComponents, FeatureDescription, Labels, HintText, ErrorMessages, RequiredAndOptionalFields } from 'docs';
import { FormControl, Input, Select, Text, Stack, Checkbox, Link, RadioButton } from '@pleo-io/telescope';
import { CategoryEntertainment, SpeechBubble, Wallet, MinusCircle, Search } from '@pleo-io/telescope-icons';
import dollarsign from '@/assets/dollarsign.svg';
import jeppe from '@/assets/jeppe.png';
import niccolo from '@/assets/niccolo.png';
import * as React from 'react';
export default {
  Features,
  Feature,
  ComponentExample,
  RelatedComponents,
  FeatureDescription,
  Labels,
  HintText,
  ErrorMessages,
  RequiredAndOptionalFields,
  FormControl,
  Input,
  Select,
  Text,
  Stack,
  Checkbox,
  Link,
  RadioButton,
  CategoryEntertainment,
  SpeechBubble,
  Wallet,
  MinusCircle,
  Search,
  dollarsign,
  jeppe,
  niccolo,
  React
};